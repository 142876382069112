<template>
  <div class="home-container" v-if="isAccountOwner || isInstituteAdmin || isTeacher">
    <GuruBreadcrumb :link-list="linkList"/>
    <div class="columns">
      <div class="column is-6 is-offset-3">
        <nav class="panel">
          <div class="panel-heading has-text-weight-bold has-text-grey-darker panel-heading-with-action">
            <span>Student Group</span>
          </div>
          <div class="panel-block guru-main-block">
            <div><span class="has-text-weight-semibold"> Name:</span>&nbsp;&nbsp;{{studentGroup.name}}</div>
            <div class="has-text-weight-semibold">Description:&nbsp;&nbsp;</div>
            <div class="white-space-pre" v-html="studentGroup.description"></div>
            <div class="has-text-centered">
              <button class="button is-info has-text-white has-text-weight-semibold" @click="editStudentGroup">Edit</button> &nbsp;&nbsp;
              <button class="button is-danger has-text-white has-text-weight-semibold" @click="showDeleteStudentGroup">Delete</button> &nbsp;&nbsp;
              <router-link class="button" :to="studentsGroupHome">Close</router-link>
            </div>
          </div>
        </nav>
      </div>
    </div>
    <div class="table-section margin-top-20px">
      <div class="columns">
        <div class="column is-6">
          <nav class="panel">
            <div class="panel-heading has-text-weight-bold has-text-grey-darker panel-heading-with-action">
              <span>Students</span>
              <button class="button is-success has-text-white has-text-weight-semibold is-pulled-right panel-button" @click="addStudent">Add Student</button>
            </div>
            <div class="panel-block has-text-centered is-paddingless">
              <table class="table is-bordered is-fullwidth">
                <thead>
                <tr>
                  <th class="has-text-centered">Student Name</th>
                  <th class="has-text-centered">Email</th>
                  <th></th>
                </tr>
                </thead>
                <tbody>
                <tr v-for="student in studentGroup.students" v-bind:key="student.email">
                  <td class="has-text-centered">{{student.firstName}} {{student.lastName}}</td>
                  <td class="has-text-centered">{{student.email}}</td>
                  <td class="has-text-centered"><a @click="removeStudent(student)"><font-awesome-icon icon="trash-alt"></font-awesome-icon></a></td>
                </tr>
                </tbody>
              </table>
            </div>
          </nav>
        </div>
        <div class="column is-6">
          <nav class="panel">
            <div class="panel-heading has-text-weight-bold has-text-grey-darker panel-heading-with-action">
              <span>Administrators</span>
              <button class="button is-success has-text-white has-text-weight-semibold is-pulled-right panel-button" @click="addAdmin">Add Admin/Teacher</button>
            </div>
            <div class="panel-block has-text-centered is-paddingless">
              <table class="table is-bordered is-fullwidth">
                <thead>
                <tr>
                  <th class="has-text-centered">Admin Name</th>
                  <th class="has-text-centered">Email</th>
                  <th></th>
                </tr>
                </thead>
                <tbody>
                <tr v-for="admin in studentGroup.groupAdmins" v-bind:key="admin.email">
                  <td class="has-text-centered">{{admin.firstName}} {{admin.lastName}}</td>
                  <td class="has-text-centered">{{admin.email}}</td>
                  <td class="has-text-centered"><a @click="removeAdmin(admin)" v-if="showRemoveForAdmin"><font-awesome-icon icon="trash-alt"></font-awesome-icon></a></td>
                </tr>
                </tbody>
              </table>
            </div>
          </nav>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import formMixin from '../../assets/javascript/form-mixin'
import { eventBus } from './../../assets/javascript/event-bus'
import GuruBreadcrumb from './GuruBreadcrumb'
import { GURU_EVENTS } from '../../assets/javascript/constants'

export default {
  name: 'guruStudentGroup',
  components: { GuruBreadcrumb },
  props: ['currentAssociation', 'isAccountOwner', 'isInstituteAdmin', 'isTeacher', 'isStudent', 'guruInitialised'],
  inject: ['$validator'],
  mixins: [formMixin],
  data: function () {
    return {
      studentGroup: {
        name: '',
        description: '',
        students: [],
        groupAdmins: []
      },
      linkList: [
        {
          title: 'Guru Home',
          href: '/guru'
        },
        {
          title: 'Institute Home',
          href: '/guru/ins/' + this.$route.params.instituteCode
        },
        {
          title: 'Student Groups',
          href: '/guru/ins/' + this.$route.params.instituteCode + '/sgs'
        }
      ]
    }
  },
  mounted () {
    eventBus.$on(GURU_EVENTS.ADD_STUDENT_TO_STUDENT_GROUP, (uzr) => {
      this.addRemoveUserFromGroup(uzr, 'student', 'add')
    })

    eventBus.$on(GURU_EVENTS.ADD_ADMIN_TO_STUDENT_GROUP, (uzr) => {
      this.addRemoveUserFromGroup(uzr, 'staff', 'add')
    })

    eventBus.$on(GURU_EVENTS.REMOVE_STUDENT_FROM_STUDENT_GROUP, (uzr) => {
      this.addRemoveUserFromGroup(uzr, 'student', 'remove')
    })

    eventBus.$on(GURU_EVENTS.REMOVE_ADMIN_FROM_STUDENT_GROUP, (uzr) => {
      this.addRemoveUserFromGroup(uzr, 'staff', 'remove')
    })

    eventBus.$on(GURU_EVENTS.REPLACE_IN_STUDENT_GROUP, (sg) => {
      this.studentGroup.name = sg.name
      this.studentGroup.description = sg.description
    })

    eventBus.$on(GURU_EVENTS.DELETE_STUDENT_GROUP, () => {
      this.deleteStudentGroup()
    })

    this.getStudentGroup()
  },
  beforeDestroy () {
    eventBus.$off(GURU_EVENTS.ADD_STUDENT_TO_STUDENT_GROUP)
    eventBus.$off(GURU_EVENTS.ADD_ADMIN_TO_STUDENT_GROUP)
    eventBus.$off(GURU_EVENTS.REMOVE_STUDENT_FROM_STUDENT_GROUP)
    eventBus.$off(GURU_EVENTS.REMOVE_ADMIN_FROM_STUDENT_GROUP)
    eventBus.$off(GURU_EVENTS.REPLACE_IN_STUDENT_GROUP)
    eventBus.$off(GURU_EVENTS.DELETE_STUDENT_GROUP)
  },
  methods: {
    getStudentGroup () {
      this.doodleForm.errorMessage = ''
      this.executeAPI({
        url: '/api/guru-api/getStudentGroup',
        data: {
          instituteCode: this.$route.params.instituteCode,
          'insStudentGroupId': this.$route.params.insStudentGroupId
        },
        method: 'post',
        markCompleted: false,
        form: this.doodleForm,
        jdaCategory: window.jda.CATEGORY.GURU,
        successAction: (data) => {
          this.studentGroup = data.sg
        },
        jdaEvent: 'guru-get-student-group',
        jdaLabel: ''
      })
    },
    editStudentGroup () {
      eventBus.$emit(GURU_EVENTS.SHOW_IN_SIDEBAR, () => import('./GuruAddEditStudentGroup.vue'),
        { mode: 'edit', instituteCode: this.$route.params.instituteCode, initData: this.studentGroup })
    },
    deleteStudentGroup () {
      this.doodleForm.errorMessage = ''
      this.executeAPI({
        url: '/api/guru-api/deleteStudentGroup',
        data: {
          instituteCode: this.$route.params.instituteCode,
          insStudentGroupId: this.$route.params.insStudentGroupId
        },
        method: 'post',
        markCompleted: true,
        form: this.doodleForm,
        jdaCategory: window.jda.CATEGORY.GURU,
        successAction: (data) => {
          eventBus.$emit(GURU_EVENTS.CONFIRMATION_ACTION_SUCCESS_NOTIFICATION)
          this.$router.push('/guru/ins/' + this.$route.params.instituteCode + '/sgs')
        },
        failureAction: () => {
          eventBus.$emit(GURU_EVENTS.CONFIRMATION_ACTION_FAILED_NOTIFICATION)
        },
        jdaEvent: 'guru-delete-student-group',
        jdaLabel: ''
      })
    },
    showDeleteStudentGroup () {
      eventBus.$emit(GURU_EVENTS.SHOW_IN_SIDEBAR, () => import('../Utils/ConfirmationSideBar.vue'),
        {
          'confirmationText': 'Are you sure, do you want to delete this group?',
          'yesText': 'Delete',
          'noText': 'Close',
          'notifyEvent': GURU_EVENTS.DELETE_STUDENT_GROUP,
          'inProgressMessage': 'JDoodle is deleting the student group, Please wait...',
          'successMessage': 'Student Group Deleted.',
          'failureMessage': 'Unable to delete the group. Please try again.'
        })
    },
    addStudent () {
      eventBus.$emit(GURU_EVENTS.SHOW_IN_SIDEBAR, () => import('./GuruIncludeUserInX.vue'),
        {
          'formTitle': 'Add Student',
          'inProgressMessage': 'Adding student to the group, Please wait...',
          'successMessage': 'Student Added Successfully',
          'actionText': 'Add Student',
          'existingUsers': this.studentGroup.students,
          'userFilter': 'student-active',
          'notifyEvent': GURU_EVENTS.ADD_STUDENT_TO_STUDENT_GROUP
        })
    },
    addAdmin () {
      eventBus.$emit(GURU_EVENTS.SHOW_IN_SIDEBAR, () => import('./GuruIncludeUserInX.vue'),
        {
          'formTitle': 'Add Group Admin',
          'inProgressMessage': 'Adding group admin to the group, Please wait...',
          'successMessage': 'Admin Added Successfully',
          'actionText': 'Add Admin',
          'existingUsers': this.studentGroup.groupAdmins,
          'userFilter': 'staff-active',
          'notifyEvent': GURU_EVENTS.ADD_ADMIN_TO_STUDENT_GROUP
        })
    },
    removeStudent (uzr) {
      eventBus.$emit(GURU_EVENTS.SHOW_IN_SIDEBAR, () => import('../Utils/ConfirmationSideBar.vue'),
        {
          'confirmationText': 'Are you sure, do you want to remove this student from the group?',
          'yesText': 'Delete',
          'noText': 'Close',
          'notifyEvent': GURU_EVENTS.REMOVE_STUDENT_FROM_STUDENT_GROUP,
          'inProgressMessage': 'JDoodle removing the user from the group, Please wait...',
          'successMessage': 'User removed from the group.',
          'failureMessage': 'Unable to remove the user from the group. Please try again.',
          'refData': uzr
        })
    },
    removeAdmin (uzr) {
      eventBus.$emit(GURU_EVENTS.SHOW_IN_SIDEBAR, () => import('../Utils/ConfirmationSideBar.vue'),
        {
          'confirmationText': 'Are you sure, do you want to remove this admin/teacher from the group?',
          'yesText': 'Delete',
          'noText': 'Close',
          'notifyEvent': GURU_EVENTS.REMOVE_ADMIN_FROM_STUDENT_GROUP,
          'inProgressMessage': 'JDoodle removing the user from the group, Please wait...',
          'successMessage': 'User removed from the group.',
          'failureMessage': 'Unable to remove the user from the group. Please try again.',
          'refData': uzr
        })
    },
    addRemoveUserFromGroup (uzr, type, action) {
      this.executeAPIWitoutValiation({
        url: '/api/guru-api/addStudentGroup',
        data: {
          instituteCode: this.$route.params.instituteCode,
          insStudentGroupId: this.$route.params.insStudentGroupId,
          email: uzr.email,
          mode: 'view',
          type: type,
          subType: action
        },
        method: 'post',
        markCompleted: false,
        form: {},
        jdaCategory: window.jda.CATEGORY.GURU,
        successAction: (data) => {
          if (type === 'student' && action === 'add') {
            this.studentGroup.students.push(uzr)
          } else if (type === 'staff' && action === 'add') {
            this.studentGroup.groupAdmins.push(uzr)
          } else if (type === 'student' && action === 'remove') {
            this.studentGroup.students.splice(this.studentGroup.students.indexOf(uzr), 1)
          } else if (type === 'staff' && action === 'remove') {
            this.studentGroup.groupAdmins.splice(this.studentGroup.groupAdmins.indexOf(uzr), 1)
          }

          if (action === 'add') {
            eventBus.$emit(GURU_EVENTS.USER_INCLUDED_NOTIFICATION, uzr)
          } else if (action === 'remove') {
            eventBus.$emit(GURU_EVENTS.CONFIRMATION_ACTION_SUCCESS_NOTIFICATION)
          }
        },
        failureAction: () => {
          if (action === 'add') {
            eventBus.$emit(GURU_EVENTS.USER_INCLUDE_FAILED_NOTIFICATION)
          } else if (action === 'remove') {
            eventBus.$emit(GURU_EVENTS.CONFIRMATION_ACTION_FAILED_NOTIFICATION)
          }
        },
        jdaEvent: (action === 'add') ? 'guru-add-user-to-group' : 'guru-delete-user-from-group',
        jdaLabel: ''
      })
    }
  },
  computed: {
    studentsGroupHome () {
      return this.insHome + '/sgs'
    },
    insHome () {
      return '/guru/ins/' + this.$route.params.instituteCode
    },
    showRemoveForAdmin () {
      return this.studentGroup.groupAdmins.length > 1
    }

  }
}
</script>

<style scoped lang="scss">
  .guru-ins-title {
    font-size: 1.2em;
    color: rgb(88, 88, 90);
    font-weight: bold;
    margin-bottom: 20px;
  }

  .table-section {
    padding: 2%;
  }

  .panel-heading-with-action {
    padding: 0.75em;
  }

  .panel-button {
    margin-top: -5px;
    border-width: 0px;
    font-size: 0.85em;
  }
</style>
